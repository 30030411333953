/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { populateProfile } from "../../../store/actions/user";

function UserDropdownV2({ user }) {
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    const expires = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    document.cookie = `NADIRA:token=${null}; expires=${expires.toUTCString()}; path=/;`;

    dispatch(populateProfile(null));
  };
  return (
    <li className="dropdown">
      <a
        href="#"
        data-toggle="dropdown"
        className="nav-link dropdown-toggle nav-link-md nav-link-user"
      >
        <img
          alt="images"
          src={
            user?.image ?? require("../../../assets/img/avatar/avatar-1.png")
          }
          className="rounded-circle mr-1"
        />
        <div className="d-none d-md-inline-block">Hi, {user?.name}</div>
      </a>
      <div className="dropdown-menu dropdown-menu-right">
        <NavLink
          to={"/profile"}
          activeStyle={{
            color: "#6777ef",
          }}
          exact
          className="dropdown-item has-icon"
        >
          <i className="far fa-user" />
          <span className="ml-2">Profile</span>
        </NavLink>

        <div className="dropdown-divider" />
        <a
          href="#"
          className="dropdown-item has-icon text-danger"
          onClick={handleLogout}
        >
          <i className="fas fa-sign-out-alt" /> Logout
        </a>
      </div>
    </li>
  );
}

export default UserDropdownV2;
