import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const mySwal = withReactContent(Swal);

export const mySwalError = ({ status, error, basic = "Server error" }) => {
  return mySwal.fire({
    icon: "error",
    text: `${error ?? basic}${status ? " - " + status : ""}`,
  });
};
export const mySwalSuccess = ({ message, basic = "Berhasil" }) => {
  return mySwal.fire({
    icon: "success",
    text: message ?? basic,
  });
};
