/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import UserDropdownV2 from "./UserDropdownV2";

import { connect } from "react-redux";

export const HeaderV2 = ({ user, className, periode }) => {
  return (
    <>
      <nav
        className={[
          "navbar navbar-expand-lg main-navbar bg-primary",
          className,
        ].join(" ")}
      >
        <ul className="navbar-nav mr-auto align-items-center">
          <li>
            <Link to="#" data-toggle="sidebar" className="nav-link nav-link-lg">
              <i className="fas fa-bars" />
            </Link>
          </li>
          <li className="d-none d-sm-block">
            <span className="text-white h5 mb-0 font-weight-600">
              {periode?.name ?? "Periode Tidak Aktif"}
            </span>
          </li>
        </ul>
        <ul className="navbar-nav navbar-right">
          <UserDropdownV2 user={user} />
        </ul>
      </nav>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
  periode: user?.periode,
});

export default connect(mapStateToProps)(HeaderV2);
