import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom/";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function NavbarGlobal({ idUser }) {
  const activeMenu = useLocation().pathname.split("/")[1];

  return (
    <nav
      className="navbar navbar-expand-lg bg-white border-bottom"
      style={{
        left: 0,
        right: 0,
        position: "relative",
      }}
    >
      <div className="container">
        <Link className="navbar-brand" to="/home">
          <img
            src={require("../../assets/img/pem-logo.png")}
            alt="logo"
            height="40"
            className=""
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div
          className="navbar-home collapse navbar-collapse text-primary-all bg-white"
          id="navbarText"
        >
          <ul className="navbar-nav mr-auto">
            <li
              className={`nav-item text-dark-all ${activeMenu === "home" &&
                "active"}`}
            >
              <Link className="nav-link" to="/home">
                Home
              </Link>
            </li>
            <li
              className={`nav-item text-dark-all ${activeMenu ===
                "information" && "active"}`}
            >
              <Link className="nav-link" to="/information">
                Informasi
              </Link>
            </li>
            <li
              className={`nav-item text-dark-all ${activeMenu === "document" &&
                "active"}`}
            >
              <Link className="nav-link" to="/document">
                Dokumen
              </Link>
            </li>
            <li
              className={`nav-item text-dark-all ${activeMenu ===
                "list-proposal" && "active"}`}
            >
              <Link className="nav-link" to="/list-proposal">
                Data Penelitian dan PkM
              </Link>
            </li>
          </ul>
          <Link
            to={idUser ? "/dashboard" : "/auth/login"}
            className="btn btn-primary text-white"
          >
            Masuk
          </Link>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({
  idUser: state?.user?.id,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarGlobal);
