import React from "react";

export default function FooterV2({ className = "" }) {
  return (
    <footer className={`main-footer ${className}`}>
      <div className="d-flex justify-content-center">
        Copyright &copy; 2022 <div className="bullet" /> Nadira
      </div>{" "}
    </footer>
  );
}
